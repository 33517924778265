import React from "react";
import { BridgeConfig, EvmBridgeConfig } from "../../chainbridgeConfig";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useStyles } from "./styles";

export default function ErrorTransferBody({
  classes,
  close,
  homeConfig,
  homeTransferTxHash,
  transferTxHash,
}: {
  classes: any;
  close: () => void;
  homeConfig?: BridgeConfig;
  homeTransferTxHash?: string;
  transferTxHash?: string;
}) {
  return (
    <>
      <Typography sx={{ mt: 3 }} component="p" className={classes.subTitle}>
        Please check your transactions and try again later. Thanks for using
        E-Gates!
      </Typography>
      {homeConfig &&
        (homeConfig as EvmBridgeConfig).blockExplorer &&
        homeTransferTxHash && (
          <Button
            onClick={() =>
              window.open(
                `${
                  (homeConfig as EvmBridgeConfig).blockExplorer
                }/${transferTxHash}`,
                "_blank"
              )
            }
            size="small"
            className="filledButton"
            variant="outlined"
            disabled
          >
            View transaction
          </Button>
        )}
      <section className={classes.buttons}>
        <Stack direction="row" spacing={2}>
          <button className="filledButton" onClick={close}>
            Start new transfer
          </button>
        </Stack>
      </section>
    </>
  );
}
