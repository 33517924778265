import React from "react";
import { init, ErrorBoundary, showReportDialog } from "@sentry/react";
import { ThemeSwitcher } from "@chainsafe/common-theme";
import CssBaseline from "@mui/material/CssBaseline";
import Web3 from "web3";
import { BrowserRouter as Router } from "react-router-dom";

import { ChainbridgeRoutes } from "./routes";
import { lightTheme } from "./themes/LightTheme";
import {
  ChainbridgeProvider,
  NetworkManagerProvider,
  LocalProvider,
} from "@chainsafe/chainbridge-ui-core";
import { AppWrapper } from "./layouts";
import { chainbridgeConfig } from "./chainbridgeConfig";
import { utils } from "ethers";
import { ReactComponent as ErrorSvg } from "./media/Icons/error-icon.svg";
import "@chainsafe/common-theme/dist/font-faces.css";

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_SENTRY_DSN_URL &&
  process.env.REACT_APP_SENTRY_RELEASE
) {
  init({
    dsn: process.env.REACT_APP_SENTRY_DSN_URL,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });
}

const App: React.FC<{}> = () => {
  const web3 = new Web3();
  const {
    __RUNTIME_CONFIG__: {
      UI: { wrapTokenPage = false } = {},
      CHAINBRIDGE: { chains },
    },
  } = window;

  const tokens = chainbridgeConfig.chains
    .filter((c) => c.type === "Ethereum")
    .reduce((tca, bc: any) => {
      if (bc.networkId) {
        return {
          ...tca,
          [bc.networkId]: bc.tokens,
        };
      } else {
        return tca;
      }
    }, {});

  const changeNetwork = async (resetError: any) => {
    const chainId = 56; // Binance Smart Chain

    if (window.ethereum.networkVersion !== chainId) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: web3.utils.toHex(chainId) }],
        });
        resetError();
      } catch (err: any) {
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: "Binance Smart Chain",
                chainId: web3.utils.toHex(chainId),
                nativeCurrency: {
                  name: "Binance Smart Chain",
                  decimals: 18,
                  symbol: "BNB",
                },
                rpcUrls: [
                  "https://bsc-dataseed.binance.org/",
                ],
              },
            ],
          });
          resetError();
        }
      }
    }
  };

  return (
    <ErrorBoundary
      fallback={({ error, componentStack, eventId, resetError }) => (
        <div className="errorBoundaryWr">
          <div className="errorBoundary">
            <h1 className="errorBoundaryTitle">Check network in metamask</h1>
            <p className="errorBoundaryText">
              An error occurred and has been logged.
              <br />
              Looks like the <strong>wrong network</strong> is selected in the
              metamask.
              <br />
              Please, select a <strong>Mainnet Network</strong> to continue.
            </p>
            <div className="errorBoundaryIcon">
              <ErrorSvg />
            </div>
            <div className="errorBoundaryBtnGroup">
              <button
                className="filledButton"
                onClick={() => changeNetwork(resetError)}
              >
                Change network
              </button>
            </div>
          </div>
        </div>
      )}
      onReset={() => window.location.reload()}
    >
      <ThemeSwitcher themes={{ light: lightTheme }}>
        <CssBaseline />
        <LocalProvider
          networkIds={[5]}
          checkNetwork={false}
          tokensToWatch={tokens}
          onboardConfig={{
            walletSelect: {
              wallets: [{ walletName: "metamask", preferred: true }],
            },
            subscriptions: {
              network: (network: any) =>
                network && console.log("domainId: ", network),
              balance: (amount: any) =>
                amount && console.log("balance: ", utils.formatEther(amount)),
            },
          }}
        >
          <NetworkManagerProvider>
            <ChainbridgeProvider chains={chains}>
              <Router>
                <AppWrapper wrapTokenPage={wrapTokenPage}>
                  <ChainbridgeRoutes wrapTokenPage={wrapTokenPage} />
                </AppWrapper>
              </Router>
            </ChainbridgeProvider>
          </NetworkManagerProvider>
        </LocalProvider>
      </ThemeSwitcher>
    </ErrorBoundary>
  );
};

export default App;
