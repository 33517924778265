import React from "react";
import {
  useNetworkManager,
  useChainbridge,
} from "@chainsafe/chainbridge-ui-core";
import {
  Button,
  Modal,
  ProgressBar,
  Typography,
} from "@chainsafe/common-components";
import { ReactComponent as WalletType } from "../../media/Icons/wallet-type.svg";
import { useStyles } from "./styles";

const NetworkSelectModal = () => {
  const classes = useStyles();
  const { isReady, chains } = useChainbridge();
  const { walletType, setWalletType } = useNetworkManager();
  const isActive =
    walletType !== "unset" && walletType !== "Ethereum" && !isReady;
  const modalStyle = isActive ? classes.rootActivve : classes.root;

  return (
    <Modal
      active={isActive}
      closePosition="right"
      className={modalStyle}
      injectedClass={{
        inner: classes.slide,
      }}
    >
      {walletType === "select" && (
        <>
          <Typography variant="h3" component="p">
            Please select a wallet type
          </Typography>
          <WalletType className={classes.walletTypeIcon}/>
          <section className={classes.buttons}>
            {chains?.every((item) => item.type === "Ethereum") ? (
              <button
                className="filledButton"
                onClick={() => setWalletType("Ethereum")}
              >
                Use Ethereum wallet
              </button>
            ) : (
              <>
                <button
                  className="filledButton"
                  onClick={() => setWalletType("Ethereum")}
                >
                  Use Ethereum wallet
                </button>
                <button
                  className="filledButton"
                  onClick={() => setWalletType("Substrate")}
                >
                  Use Substrate wallet
                </button>
              </>
            )}
          </section>
        </>
      )}
      {walletType === "Substrate" && (
        <>
          <Typography variant="h2" component="p">
            Connecting to node
          </Typography>
          <ProgressBar size="small" variant="primary" />
        </>
      )}
    </Modal>
  );
};

export default NetworkSelectModal;
