import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme";

export const useStyles = makeStyles(
  ({ constants, palette, zIndex }: ITheme) => {
    return createStyles({
      rootActivve: {
        backgroundColor: "rgba(0,0,0,0.8)",
      },
      root: {},
      slide: {
        background: "#2C2C2C",
        borderRadius: "10px",
        padding: `${constants.generalUnit}px ${constants.generalUnit * 2}px`,
        "& > p": {
          marginTop: constants.generalUnit * 2,
          marginBottom: constants.generalUnit * 3,
          textAlign: "center",
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "20px",
          color: "#FFFFFF",
        },
      },
      walletTypeIcon: {
        margin: "0 auto 30px",
      },
      buttons: {
        marginBottom: constants.generalUnit * 2,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
      },
    });
  }
);
