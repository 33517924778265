import { makeStyles, createStyles, ITheme } from "@chainsafe/common-theme";

export const useStyles = makeStyles(({ constants, palette }: ITheme) =>
  createStyles({
    root: {
      padding: `${constants.generalUnit * 3}px 0 ${
        constants.generalUnit * 3
      }px`,
      paddingTop: "10px",
      position: "relative",
    },
    walletArea: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      marginBottom: '20px',
    },
    label: {
      fontSize: "0.75rem !important",
      color: "#FFFFFF",
      marginLeft: '10px !important',
    },
    connectButton: {
      margin: `${constants.generalUnit * 3}px 0 ${constants.generalUnit * 6}px`,
    },
    connecting: {
      textAlign: "center",
      marginBottom: constants.generalUnit * 2,
    },
    connected: {
      width: "100%",
      "& > *:first-child": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      },
    },
    changeButton: {
      cursor: "pointer",
      fontSize: "0.9rem !important",
      color: "#fdcb12",
    },
    networkName: {
      // padding: `${constants.generalUnit * 2}px ${
      //   constants.generalUnit * 1.5
      // }px`,
      color: "rgba(255, 255, 255, 0.4);",
      marginTop: constants.generalUnit,
      marginBottom: constants.generalUnit * 3,
      background: "rgba(255, 255, 255, 0.05)",
      border: "1px solid rgba(253, 203, 18, 0.07)",
      borderRadius: "50px",
      fontSize: '14px !important',
      lineHeight: '14px !important',
      padding: '14px 12px',
    },
    formArea: {
      "&.disabled": {
        opacity: 0.4,
      },
    },
    currencySection: {
      // display: "flex",
      // flexDirection: "row",
      // justifyContent: "space-between",
      // alignItems: "flex-end",
      margin: `${constants.generalUnit * 3}px 0`,
    },
    tokenInputArea: {
      // display: "flex",
      // flexDirection: "row",
      // alignItems: "flex-end",
      // justifyContent: "space-around",
    },
    tokenInputSection: {
      // width: "50%",
    },
    tokenInput: {
      // // margin: 0,
      // "& > div": {
      //   // height: 32,
      //   "& input": {
      //     borderBottomRightRadius: 0,
      //     borderTopRightRadius: 0,
      //     borderRight: 0,
      //   },
      // },
      // "& span:last-child.error": {
      //   position: "absolute",
      //   width: "calc(100% + 62px)",
      // },
    },
    maxButton: {
      height: 32,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      left: -1,
      color: palette.additional["gray"][8],
      backgroundColor: palette.additional["gray"][3],
      borderColor: palette.additional["gray"][6],
      "&:hover": {
        borderColor: palette.additional["gray"][6],
        backgroundColor: palette.additional["gray"][7],
        // color: palette.common.white.main,
      },
      "&:focus": {
        borderColor: palette.additional["gray"][6],
      },
    },
    currencySelector: {
      // width: "50%",
      paddingRight: constants.generalUnit,
      "& *": {
        cursor: "pointer",
      },
    },
    token: {},
    address: {
      margin: 0,
      marginBottom: constants.generalUnit * 3,
    },
    addressInput: {},
    generalInput: {
      "& > span": {
        marginBottom: constants.generalUnit,
      },
    },
    tokenItem: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
      "& img, & svg": {
        display: "block",
        height: 14,
        width: 14,
        marginRight: 10,
      },
      "& span": {
        minWidth: `calc(100% - 20px)`,
        textAlign: "left",
      },
    },
    fees: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      marginBottom: constants.generalUnit,
      "& > *": {
        display: "block",
        width: "50%",
        color: palette.additional["gray"][8],
        marginBottom: constants.generalUnit / 2,
        "&:nth-child(even)": {
          textAlign: "right",
        },
      },
    },
    accountSelector: {
      marginBottom: 24,
    },
  })
);
