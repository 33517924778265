import { makeStyles, createStyles, ITheme } from "@chainsafe/common-theme";

export const useStyles = makeStyles(({ constants }: ITheme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      paddingTop: "40px",
      zIndex: 10,
    },
    buttons: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& *": {
        marginRight: constants.generalUnit,
        textDecoration: "none",
      },
    },
    titles: {
      fontWeight: 600,
      fontSize: "32px",
      lineHeight: "20px",
      marginBottom: "60px",

      "& > span": {
        color: "#FDCB12",
        display: "inline-block",
        marginLeft: "5px",
      },
    },
    text: {
      fontSize: "14px",
      lineHeight: "24px",
      marginBottom: "60px",
    },
  })
);
