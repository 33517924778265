import { makeStyles, createStyles, ITheme } from "@chainsafe/common-theme";

export const useStyles = makeStyles(({ constants, palette, zIndex }: ITheme) =>
  createStyles({
    root: {
      zIndex: zIndex?.blocker,
      backgroundColor: '#2C2C2C !important',
      borderRadius: '10px',
      padding: '42px 40px',
      position: "absolute",
      width: 'auto !important',
      "& li": {
        position: "relative",
        padding: `${constants.generalUnit}px 0 ${constants.generalUnit}px ${
          constants.generalUnit * 8
        }px`,
        "&:before": {
          content: "''",
          display: "block",
          backgroundColor: palette.additional["gray"][2],
          height: constants.generalUnit,
          width: constants.generalUnit,
          borderRadius: "50%",
          position: "absolute",
          top: "50%",
          left: constants.generalUnit * 4,
          transform: "translate(-50%, -50%)",
        },
      },
    },
    title: {
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '20px',
      textAlign: 'center',
      color: '#FFFFFF',
    },
    subtitle: {
      margin: `${constants.generalUnit * 2}px 0`,
      fontSize: '16px',
      lineHeight: '26px',
      letterSpacing: '0.02em',
      color: '#FFFFFF',
    },
    agreement: {
      margin: `${constants.generalUnit * 2}px 0`,
    },
    startButton: {
      backgroundColor: palette.additional["preflight"][1],
      color: palette.additional["preflight"][2],
      marginBottom: constants.generalUnit * 2,
    },
    backdrop: {
      position: "absolute",
      zIndex: zIndex?.layer4,
      background: 'rgba(0,0,0,0.8) !important',
      opacity: '1 !important',
    },
    btnWr: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  })
);
