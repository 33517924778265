import React from "react";

import Typography from "@mui/material/Typography";

export default function InitTransferBody({ classes }: { classes: any }) {
  return (
    <div className={classes.initCopy}>
      <Typography style={{ marginBottom: 15 }} className={classes.subTitle}>
        Deposit pending...
      </Typography>
      <Typography className={classes.subTitle}>
        This should take a few minutes.
        <br />
        Please do not refresh or leave the page.
      </Typography>
    </div>
  );
}
